import React from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider} from 'styled-components';
import {graphql, StaticQuery} from 'gatsby';

import EnvContext from '../context/EnvContext';
import {theme} from '../common/theme/appModern';

export default function EveryPageWrapper({children}) {
	return (
		<ThemeProvider theme={theme}>
			<StaticQuery
				query={graphql`
					query EnvQuery {
						site {
							siteMetadata {
								domain
								title
								description
								author
							}
						}
					}
				`}
				render={(data) => (
					<EnvContext.Provider
						value={{
							title: data.site.siteMetadata.title,
							description: data.site.siteMetadata.description,
							author: data.site.siteMetadata.author,
							domain: data.site.siteMetadata.domain,
							domainTitle: data.site.siteMetadata.domain === 'jump.me' ? 'Jump.me' : 'Jump.in'
						}}
					>
						{typeof children === 'function'
							? children({
									title: data.site.siteMetadata.title,
									description: data.site.siteMetadata.description,
									author: data.site.siteMetadata.author,
									domain: data.site.siteMetadata.domain,
									domainTitle: data.site.siteMetadata.domain === 'jump.me' ? 'Jump.me' : 'Jump.in'
							  })
							: children}
					</EnvContext.Provider>
				)}
			/>
		</ThemeProvider>
	);
}

EveryPageWrapper.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
};
