import {useContext} from 'react';

import EnvContext from '../context/EnvContext';

/**
 * @return {{author: string, domain: string, description: string, title: string, domainTitle: string}}
 */
export default function useEnvContext() {
	const {title, description, author, domain, domainTitle} = useContext(EnvContext);
	return {title, description, author, domain, domainTitle};
}
