import React, {useState, useRef} from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';

import Fade from 'react-reveal/Fade';
import {Icon} from 'react-icons-kit';
import {menu} from 'react-icons-kit/feather/menu';
import {x} from 'react-icons-kit/feather/x';
import Logo from '../../../common/components/UIElements/Logo';
import Button from '../../../common/components/Button';
import Container from '../../../common/components/UI/Container';
import useOnClickOutside from '../../../common/hooks/useOnClickOutside';
import NavbarWrapper, {MenuArea, MobileMenu} from './navbar.style';
import useEnvContext from '../../../hooks/useEnvContext';

import LogoImageIn from '../../../common/assets/image/appModern/logo-white.png';
import LogoImageInAlt from '../../../common/assets/image/appModern/logo.png';

import LogoImageMe from '../../../images/logo-white_jump-me.png';
import LogoImageMeAlt from '../../../images/logo-blk_jump-me.png';

const Navbar = () => {
	const data = useStaticQuery(graphql`
		query {
			appModernJson {
				navbar {
					logo {
						publicURL
					}
					navMenu {
						id
						label
						path
						offset
					}
				}
			}
		}
	`);
	const {navMenu} = data.appModernJson.navbar;
	const [state, setState] = useState({
		search: '',
		searchToggle: false,
		mobileMenu: false
	});

	const searchRef = useRef(null);
	useOnClickOutside(searchRef, () => setState({...state, searchToggle: false}));

	const toggleHandler = (type) => {
		if (type === 'search') {
			setState({
				...state,
				search: '',
				searchToggle: !state.searchToggle,
				mobileMenu: false
			});
		}

		if (type === 'menu') {
			setState({
				...state,
				mobileMenu: !state.mobileMenu
			});
		}
	};

	/*
  const handleOnChange = (event) => {
    setState({
      ...state,
      search: event.target.value,
    })
  }

  const handleSearchForm = (event) => {
    event.preventDefault()

    if (state.search !== "") {
      console.log("search data: ", state.search)

      setState({
        ...state,
        search: "",
      })
    } else {
      console.log("Please fill this field.")
    }
  }
  */

	const scrollItems = [];

	navMenu.forEach((item) => {
		scrollItems.push(item.path.slice(1));
	});

	/*
  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    })
  }
  */

	const {domainTitle, domain} = useEnvContext();

	return (
		<NavbarWrapper className="navbar">
			<Container>
				<Logo href="/" logoSrc={domain === 'jump.me' ? LogoImageMe : LogoImageIn} title={domainTitle} className="main-logo" />
				<Logo href="/" logoSrc={domain === 'jump.me' ? LogoImageMeAlt : LogoImageInAlt} title={domainTitle} className="logo-alt" />
				{/* end of logo */}

				<MenuArea className={state.searchToggle ? 'active' : ''}>
					<ul className="menu">
						{navMenu.map((menu, index) => (
							<li key={`menu_key${index}`}>
								<Link to={menu.path}>{menu.label}</Link>
							</li>
						))}
					</ul>
					{/* end of main menu */}

					<Button
						as="a"
						href="https://l.jump.in/iosWeb"
						target="_blank"
						rel="noopener noreferrer"
						className="trail"
						title={domain === 'jump.me' ? 'Jump me' : 'Get Jump.in'}
						style={{marginLeft: '24px'}}
					/>

					<Button
						className="menubar"
						icon={
							state.mobileMenu ? (
								<Icon className="bar" icon={x} />
							) : (
								<Fade>
									<Icon className="close" icon={menu} />
								</Fade>
							)
						}
						color="#0F2137"
						variant="textButton"
						onClick={() => toggleHandler('menu')}
					/>
				</MenuArea>
			</Container>

			{/* start mobile menu */}
			<MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
				<Container>
					<ul className="menu">
						{navMenu.map((menu, index) => (
							<li key={`menu_key${index}`}>
								<Link to={menu.path}>{menu.label}</Link>
							</li>
						))}
					</ul>
					<Button as="a" href="https://l.jump.in/iosWeb" target="_blank" rel="noopener noreferrer" title={domain === 'jump.me' ? 'Jump me' : 'Get Jump.in'} />
				</Container>
			</MobileMenu>
			{/* end of mobile menu */}
		</NavbarWrapper>
	);
};

export default Navbar;
