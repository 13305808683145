import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';

import Box from '../../../common/components/Box';
import Text from '../../../common/components/Text';
import Image from '../../../common/components/Image';
import Logo from '../../../common/components/UIElements/Logo';
import Heading from '../../../common/components/Heading';
import Container from '../../../common/components/UI/Container';
import FooterArea, {WidgetArea, MenuArea, Menu, MenuItem, CopyrightText} from './footer.style';

import LogoImageIn from '../../../common/assets/image/appModern/logo-white.png';
import LogoImageMe from '../../../images/logo-white_jump-me.png';

import useEnvContext from '../../../hooks/useEnvContext';

const Footer = () => {
	const data = useStaticQuery(graphql`
		query {
			appModernJson {
				footer {
					widgets {
						id
						icon {
							publicURL
						}
						title
						description
					}
				}
			}
		}
	`);
	const {widgets} = data.appModernJson.footer;
	const date = new Date();
	const year = date.getFullYear();

	const {domain, domainTitle} = useEnvContext();

	return (
		<FooterArea>
			<Container>
				<WidgetArea>
					{widgets.map((item) => (
						<Box className="col" key={`footer-widget--key${item.id}`}>
							<Image src={item.icon.publicURL} alt={item.title} />
							<Heading as="h3" content={item.title} />
							<Text content={item.description} />
						</Box>
					))}
				</WidgetArea>
				{/* End of footer widgets area */}
				<MenuArea>
					<Logo href="/" logoSrc={domain === 'jump.me' ? LogoImageMe : LogoImageIn} title={domainTitle} className="logo" />
					<Menu>
						<MenuItem>
							<Link to="/">Home</Link>
						</MenuItem>
						<MenuItem>
							<Link to="/community-guidelines">Community Guidelines</Link>
						</MenuItem>
						<MenuItem>
							<Link to="/privacy">Privacy</Link>
						</MenuItem>
						<MenuItem>
							<Link to="/support">Support</Link>
						</MenuItem>
						<MenuItem>
							<a href="mailto:support@jump.in?subject=Jump.in website CONTACT query">Contact</a>
						</MenuItem>
					</Menu>
					<CopyrightText>Copyright © {year} Jump Corporation</CopyrightText>
				</MenuArea>
				{/* End of footer menu area */}
			</Container>
		</FooterArea>
	);
};

export default Footer;
